import {
  BooleanField,
  Button,
  FunctionField,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
  WrapperField,
} from "react-admin";

import { EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT } from "../../constants";
import { useModalState } from "../../hooks";
import { DataKind, PremiumPlanKind, Resource, UserResponse } from "../../types";
import { componentUtils } from "../component_utils";
import {
  CustomDateTimeField,
  CustomList,
  CustomReferenceField,
  InviteUserToFieldwireModal,
  LabelWithInfoTooltip,
} from "../custom";

// Normally we automatically generate these kinds of choices from an enum.
// However, in this case, there isn't a consistent mapping between the `id`
// value and the `name` value, which is why we specify them manually.
const premiumPlanKindChoices = [
  { id: PremiumPlanKind.StripePro, name: "Stripe Pro" },
  { id: PremiumPlanKind.StripeBusiness, name: "Stripe Business" },
  { id: PremiumPlanKind.StripeBusinessPlus, name: "Stripe Business Plus" },

  { id: PremiumPlanKind.OtcPro, name: "OTC-2 - Pro" },
  { id: PremiumPlanKind.OtcBusiness, name: "OTC-2 - Business" },
  { id: PremiumPlanKind.OtcBusinessPlus, name: "OTC-2 - Business Plus" },

  { id: PremiumPlanKind.EnterprisePro, name: "Pro - Invoice" },
  { id: PremiumPlanKind.EnterpriseBusiness, name: "Business - Invoice" },
  {
    id: PremiumPlanKind.EnterpriseBusinessPlus,
    name: "Business Plus - Invoice",
  },
  { id: PremiumPlanKind.EnterprisePremier, name: "Enterprise - Invoice" },
];

// "By default, <List> does not refresh the data as soon as the user enters
// data in the filter form. Instead, it waits for half a second of user
// inactivity (via `lodash.debounce`) before calling the dataProvider
// on filter change. This is to prevent repeated calls to the API."
//
// Source: https://marmelab.com/react-admin/List.html#debounce
const userFilters = [
  <TextInput label="First Name" source="first_name_like" alwaysOn />,
  <TextInput label="Last Name" source="last_name_like" alwaysOn />,
  <TextInput label="Email" source="email_like" alwaysOn />,
  <TextInput label="Company" source="company_name_like" alwaysOn />,
  <TextInput label="Lead Score (>=)" source="lead_score_gte" alwaysOn />,
  <SelectInput
    choices={premiumPlanKindChoices}
    label="Plan Name"
    source="premium_plan_kind_eq"
    alwaysOn
  />,
  <TextInput label="Region" source="region_like" alwaysOn />,
  <TextInput label="UTM" source="utm_like" alwaysOn />,
  <TextInput
    label={
      <LabelWithInfoTooltip
        labelText="Phone Number"
        tooltipText={`Please search for phone numbers by entering a specific section of the number that is not dependent on formatting.
          For example, for US numbers, try entering the last 4 digits of the phone number.
          This is because we haven't always enforced a consistent formatting for phone numbers when we've collected them from users.`}
      />
    }
    source="phone_number_like"
    alwaysOn
  />,
  <TextInput label="Country Code" source="country_code_eq" alwaysOn />,
];

function InviteUserToFieldwire() {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    // Any admin user can invite a user to Fieldwire. Therefore, we do not
    // wrap the button and modal in any sort of Authorization component.
    <>
      <Button
        label="Invite New User"
        onClick={handleOpen}
        variant="fieldwire-primary"
      />
      <InviteUserToFieldwireModal
        handleClose={handleClose}
        modalOpen={modalOpen}
      />
    </>
  );
}

function UserList() {
  return (
    <CustomList
      checkPermissionFor={DataKind.User}
      // We are overriding the default sort because sorting by descending ID was causing the DB query
      // to timeout in the backend for Business Plus queries.
      // This was happening because we had to scan the entire users table since there weren't enough
      // users on Business Plus to satisfy the query.
      // Sorting by created_at is faster for this particular query since the query planner takes a
      // different path (uses Hash Join).
      defaultSort={{ field: "created_at", order: "DESC" }}
      displayCreate={false}
      fieldwireActions={<InviteUserToFieldwire />}
      filters={userFilters}
    >
      <CustomReferenceField
        label="Plan Name"
        source="account_id"
        reference={Resource.Account}
        sortable={false}
      >
        <TextField source="plan_info" />
      </CustomReferenceField>
      <TextField label="Id" source="id" />
      <TextField label="First Name" source="first_name" />
      <TextField label="Last Name" source="last_name" />
      <TextField label="Email" source="email" />
      <NumberField label="Lead Score" source="lead_score" />
      <TextField label="Phone Number" source="phone_number" sortable={false} />
      <TextField label="Company" source="company" sortable={false} />
      <WrapperField label="Number Of Employees" sortable={false}>
        <NumberField source="min_employees" />
        <>-</>
        <NumberField source="max_employees" />
      </WrapperField>
      <CustomDateTimeField label="Created At" source="created_at" />
      <CustomDateTimeField
        label="Current Sign In At"
        source="current_sign_in_at"
      />
      <FunctionField
        label={
          <LabelWithInfoTooltip
            labelText="Email Confirmation Status"
            tooltipText={EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT}
          />
        }
        render={(userRecord: UserResponse) =>
          componentUtils.snakeCaseToCapitalCase(
            userRecord.email_confirmation_status
          )
        }
        sortable={false}
      />
      <CustomReferenceField
        label="Invited By"
        source="invited_by_id"
        reference={Resource.User}
        sortable={false}
      >
        <TextField source="first_name" />
      </CustomReferenceField>
      <BooleanField
        label="Is High Value"
        source="is_high_value"
        sortable={false}
      />
      <TextField label="City" source="city" />
      <TextField label="Region" source="region" />
      <TextField label="Utm Campaign" source="utm_campaign" sortable={false} />
      <TextField label="Utm Content" source="utm_content" sortable={false} />
      <TextField label="Utm Medium" source="utm_medium" sortable={false} />
      <TextField label="Utm Source" source="utm_source" sortable={false} />
      <TextField label="Utm Term" source="utm_term" sortable={false} />
      <TextField label="Country Code" source="country_code" />
      {/* MISSING: Account History */}
      <CustomDateTimeField label="Locked At" source="locked_at" />
      <CustomReferenceField
        label="Account Blocked At"
        source="account_id"
        reference={Resource.Account}
        sortable={false}
      >
        <TextField source="blocked_at" />
      </CustomReferenceField>
      {/* MISSING: Sso Domains */}
    </CustomList>
  );
}

export default UserList;
